import {
  MASAMUNE_ACCEPT_CONSENTS,
  MASAMUNE_ADD_SEQUENCE_HISTORY,
  MASAMUNE_CLEAR_CONNECTION_PROCESSING_TIME,
  MASAMUNE_CLOSE_MODALS_AND_WIDGETS,
  MASAMUNE_CONNECTION_FREEZE,
  MASAMUNE_FORCE_SHOW_BANK_SELECTION_SCREEN_FOR_PROVIDER,
  MASAMUNE_HIDE_MODAL,
  MASAMUNE_INDEX_CHANGED,
  MASAMUNE_SET_ACCESS_TOKEN,
  MASAMUNE_SET_AKOYA_CONNECTION,
  MASAMUNE_SET_CLIENT_CALLBACK_SETUP,
  MASAMUNE_SET_CONNECTION_PROCESSING_TIME,
  MASAMUNE_SET_CONSENTS,
  MASAMUNE_SET_CONSUMER,
  MASAMUNE_SET_CRITICAL_ISSUE_OCCURRED,
  MASAMUNE_SET_CURRENT_CONSUMER,
  MASAMUNE_SET_CURRENT_VENDOR,
  MASAMUNE_SET_CURRENT_WIDGET_FLOW,
  MASAMUNE_SET_CUSTOM_SELECTED_INSTITUTION_FOR_PROVIDER,
  MASAMUNE_SET_DATA_PULL_PROCESSING_TIME,
  MASAMUNE_SET_DATA_PULL_TIMEOUT,
  MASAMUNE_SET_ERRORED_INSTITUTIONS,
  MASAMUNE_SET_FLINKS_CONNECTION,
  MASAMUNE_SET_FOUND_INSTITUTIONS,
  MASAMUNE_SET_IBV_SEQUENCE,
  MASAMUNE_SET_INTENT_URL,
  MASAMUNE_SET_IS_LANDING_PAGE,
  MASAMUNE_SET_IS_LAST_VENDOR,
  MASAMUNE_SET_PRODUCT_REQUEST,
  MASAMUNE_SET_RETRY_MODE,
  MASAMUNE_SET_SOPHTRON_CONNECTION,
  MASAMUNE_SET_SWITCH_TO_NEXT_VENDOR,
  MASAMUNE_SET_TERMS_AND_CONDITIONS,
  MASAMUNE_SET_UCW_CONNECTION,
  MASAMUNE_SET_UNMOUNT_ON_COMPLETION,
  MASAMUNE_SET_WIDGET_CONFIGURATION,
  MASAMUNE_SET_YODLEE_CONNECTION,
  MASAMUNE_SHOW_MODAL,
  MASAMUNE_TOGGLE_LOADING,
  MASAMUNE_TOGGLE_PRODUCT_REQUEST_PROCESSING,
  MASAMUNE_TOGGLE_WIDGET_PROCESSING,
  MASAMUNE_TOGGLE_WIDGET_RENDER,
  NEW_CONNECTION,
} from "../constants/masamune-widget";

const initialState = {
  currentProvider: null,
  processing: false,
  productRequestProcessing: false,
  sophtronConnection: null, // Filled by the sequence API if existing or API response if just created
  yodleeConnection: null,
  flinksConnection: null, // Filled by the sequence API if existing or API response if just created
  ucwConnection: null,
  intentUrl: null,
  productRequest: null,
  pullProcessingTimeStart: null,
  connectionProcessingTimeStart: null,
  customInstitutionSelected: {
    yodlee: null,
    sophtron: null,
    flinks: null,
    akoya: null,
    ucw: null,
  },
  forceShowBankSelectionScreenForProvider: {
    yodlee: false,
    sophtron: false,
    flinks: false,
    akoya: false,
    ucw: false,
  },
  currVendorIdx: 0, // Default is first
  switchToNextVendor: false,
  vendorSequence: [],
  ibvSequence: null,
  isLoading: true,
  criticalIssueOccurred: false,
  retryMode: false,
  dataPullTimeout: null,
  shouldRender: true,
  activeConnections: [],
  accessToken: null,
  consumer: null,
  isLastVendor: false,
  clientCallbackFunctions: {
    onError: () => {},
    onSuccess: () => {},
  },
  flow: NEW_CONNECTION,
  foundInstitutions: [],
  ibvConnections: {
    flinks: null,
    yodlee: null,
    sophtron: null,
    akoya: null,
    ucw: null,
  },
  widgetConfiguration: {
    version: "v1",
    features: {
      bankSelection: false,
      ucwBankSelectionMode: false,
    },
  },
  termsAndConditions: null,
  consentsAccepted: false,
  consents: [],
  retryWithBankSelection: false,
  showModal: false,
  showNewConnectionWidgets: false,
  connectionEstablishTimeout: 300,
  erroredInstitutions: null,
  unmountOnCompletion: true,
  isLandingPage: false,
  retryFlowInstantBankSearch: false,
  sequenceHistory: [],
};

export const MasamuneWidgetConfigurationReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case MASAMUNE_ADD_SEQUENCE_HISTORY:
      return {
        ...state,
        sequenceHistory: [...state.sequenceHistory, action.payload],
      };
    case MASAMUNE_SET_RETRY_MODE:
      return { ...state, ...action.payload };
    case MASAMUNE_CONNECTION_FREEZE:
      return { ...state, connectionFreeze: action.payload };
    case MASAMUNE_SHOW_MODAL:
      return { ...state, showModal: true };
    case MASAMUNE_HIDE_MODAL:
      return { ...state, showModal: false };
    case MASAMUNE_INDEX_CHANGED:
      return { ...state, ...action.payload };
    case MASAMUNE_SET_IS_LAST_VENDOR:
      return { ...state, isLastVendor: true };
    case MASAMUNE_SET_WIDGET_CONFIGURATION:
      return { ...state, ...action.payload };
    case MASAMUNE_SET_TERMS_AND_CONDITIONS:
      return { ...state, ...action.payload };
    case MASAMUNE_SET_CONSENTS:
      return { ...state, ...action.payload };
    case MASAMUNE_ACCEPT_CONSENTS:
      return { ...state, ...action.payload };
    case MASAMUNE_CLOSE_MODALS_AND_WIDGETS:
      return { ...state, ...action.payload };
    case MASAMUNE_TOGGLE_WIDGET_PROCESSING:
      return { ...state, processing: action.payload };
    case MASAMUNE_TOGGLE_WIDGET_RENDER:
      return { ...state, shouldRender: !state.shouldRender };
    case MASAMUNE_TOGGLE_PRODUCT_REQUEST_PROCESSING:
      return {
        ...state,
        productRequestProcessing: action.payload,
      };
    case MASAMUNE_SET_PRODUCT_REQUEST:
      return { ...state, productRequest: action.payload };
    case MASAMUNE_SET_CLIENT_CALLBACK_SETUP:
      return { ...state, clientCallbackFunctions: action.payload };
    case MASAMUNE_SET_CONNECTION_PROCESSING_TIME:
      return {
        ...state,
        connectionProcessingTimeStart: new Date().getTime() / 1000,
      };
    case MASAMUNE_CLEAR_CONNECTION_PROCESSING_TIME:
      return { ...state, connectionProcessingTimeStart: null };
    case MASAMUNE_SET_DATA_PULL_PROCESSING_TIME:
      // Set time in seconds
      return { ...state, pullProcessingTimeStart: new Date().getTime() / 1000 };
    case MASAMUNE_SET_CONSUMER:
      return { ...state, ...action.payload };
    case MASAMUNE_SET_IBV_SEQUENCE:
      return { ...state, ...action.payload };
    case MASAMUNE_TOGGLE_LOADING:
      return { ...state, isLoading: !state.isLoading };
    case MASAMUNE_SET_DATA_PULL_TIMEOUT:
      return { ...state, dataPullTimeout: action.payload };
    case MASAMUNE_SET_UNMOUNT_ON_COMPLETION:
      return { ...state, unmountOnCompletion: action.payload };
    case MASAMUNE_SET_INTENT_URL:
      return { ...state, intentUrl: action.payload };
    case MASAMUNE_SET_AKOYA_CONNECTION:
      return {
        ...state,
        akoyaConnection: action.payload,
        ibvConnections: {
          ...state.ibvConnections,
          ...{ akoya: action.payload },
        },
      };
    case MASAMUNE_SET_YODLEE_CONNECTION:
      return {
        ...state,
        yodleeConnection: action.payload,
        ibvConnections: {
          ...state.ibvConnections,
          ...{ yodlee: action.payload },
        },
      };
    case MASAMUNE_SET_SOPHTRON_CONNECTION:
      return {
        ...state,
        sophtronConnection: action.payload,
        ibvConnections: {
          ...state.ibvConnections,
          ...{ sophtron: action.payload },
        },
      };
    case MASAMUNE_SET_FLINKS_CONNECTION:
      return {
        ...state,
        flinksConnection: action.payload,
        ibvConnections: {
          ...state.ibvConnections,
          ...{ flinks: action.payload },
        },
      };
    case MASAMUNE_SET_UCW_CONNECTION:
      return {
        ...state,
        ucwConnection: action.payload,
        ibvConnections: {
          ...state.ibvConnections,
          ...{ ucw: action.payload },
        },
      };
    case MASAMUNE_FORCE_SHOW_BANK_SELECTION_SCREEN_FOR_PROVIDER:
      return {
        ...state,
        forceShowBankSelectionScreenForProvider: {
          ...state.forceShowBankSelectionScreenForProvider,
          ...action.payload,
        },
      };
    case MASAMUNE_SET_CUSTOM_SELECTED_INSTITUTION_FOR_PROVIDER:
      return {
        ...state,
        customInstitutionSelected: {
          ...state.customInstitutionSelected,
          ...action.payload,
        },
      };
    case MASAMUNE_SET_SWITCH_TO_NEXT_VENDOR:
      return { ...state, switchToNextVendor: true };
    case MASAMUNE_SET_CRITICAL_ISSUE_OCCURRED:
      return { ...state, criticalIssueOccurred: true };
    case MASAMUNE_SET_CURRENT_CONSUMER:
      return { ...state, consumer: action.payload };
    case MASAMUNE_SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case MASAMUNE_SET_CURRENT_VENDOR:
      return { ...state, ...action.payload };
    case MASAMUNE_SET_CURRENT_WIDGET_FLOW:
      return { ...state, ...action.payload };
    case MASAMUNE_SET_FOUND_INSTITUTIONS:
      return { ...state, ...action.payload };
    case MASAMUNE_SET_ERRORED_INSTITUTIONS:
      return { ...state, ...action.payload };
    case MASAMUNE_SET_IS_LANDING_PAGE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
