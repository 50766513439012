import { useDispatch, useSelector } from "react-redux";
import NotMyBankLink from "./not-my-bank-link";
import {
  requiredConsentsGiven,
  updateConsents,
} from "../../shared/masamune-widget-utils";
import { acceptConsents } from "../../actions/widget-actions";

const ConnectMyAccountButton = ({ handleActiveBtnClick }) => {
  const dispatch = useDispatch();
  const {
    currVendorIdx,
    foundInstitutions,
    ibvSequence,
    widgetConfiguration,
    consents,
  } = useSelector((state) => state.globalConfig);
  const globalConfig = useSelector((state) => state.globalConfig);
  const productRequest = useSelector(
    (state) => state.globalConfig.productRequest
  );

  const consentsGiven = requiredConsentsGiven(globalConfig);
  const allowBankSearch =
    foundInstitutions[0] &&
    (widgetConfiguration.features.enableSearchOnWrongAba ||
      (!widgetConfiguration.features.disableAkoyaSearch &&
        ibvSequence.vendors[currVendorIdx]?.vendor_name === "akoya"));

  const buttonClicked = async (e) => {
    e.preventDefault();
    handleActiveBtnClick(e);

    await updateConsents(
      consents,
      productRequest.product_request_id,
      globalConfig
    );
    dispatch(acceptConsents({ consentsAccepted: true }));
  };

  return (
    <div className="text-center">
      <button
        disabled={!consentsGiven}
        onClick={buttonClicked}
        className="
          py-4 px-8 lg:py-2 lg:px-4 rounded-full font-bold text-white
          bg-blue-500 hover:bg-blue-600
          focus:ring-4 focus:ring-blue-300
          dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
          inline-flex items-center
          disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-gray-700
        "
      >
        {foundInstitutions[0] ? (
          `Connect to ${foundInstitutions[0].name}`
        ) : (
          <>Connect my Account</>
        )}
      </button>
      <br />
      {allowBankSearch && <NotMyBankLink />}
    </div>
  );
};

export default ConnectMyAccountButton;
