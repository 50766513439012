export const MASAMUNE_SET_WIDGET_CONFIGURATION =
  "MASAMUNE/SET_WIDGET_CONFIGURATION";
export const MASAMUNE_SET_TERMS_AND_CONDITIONS =
  "MASAMUNE/SET_TERMS_AND_CONDITIONS";
export const MASAMUNE_SET_CONSENTS =
  "MASAMUNE/SET_CONSENTS";
export const MASAMUNE_ACCEPT_CONSENTS =
  "MASAMUNE/ACCEPT_CONSENTS";
export const MASAMUNE_TOGGLE_WIDGET_PROCESSING =
  "MASAMUNE/TOGGLE_WIDGET_PROCESSING";
export const MASAMUNE_TOGGLE_WIDGET_RENDER = "MASAMUNE/TOGGLE_WIDGET_RENDER";
export const MASAMUNE_TOGGLE_PRODUCT_REQUEST_PROCESSING =
  "MASAMUNE/TOGGLE_PRODUCT_REQUEST_PROCESSING";
export const MASAMUNE_SET_PRODUCT_REQUEST = "MASAMUNE/SET_PRODUCT_REQUEST";
export const MASAMUNE_SET_CLIENT_CALLBACK_SETUP =
  "MASAMUNE/SET_CLIENT_CALLBACK_SETUP";
export const MASAMUNE_SET_DATA_PULL_PROCESSING_TIME =
  "MASAMUNE/SET_DATA_PULL_PROCESSING_TIME";
export const MASAMUNE_SET_CONSUMER = "MASAMUNE/SET_CONSUMER";
export const MASAMUNE_SET_IBV_SEQUENCE = "MASAMUNE/SET_IBV_SEQUENCE";
export const MASAMUNE_TOGGLE_LOADING = "MASAMUNE/TOGGLE_LOADING";
export const MASAMUNE_SET_DATA_PULL_TIMEOUT = "MASAMUNE/SET_DATA_PULL_TIMEOUT";
export const MASAMUNE_SET_UNMOUNT_ON_COMPLETION =
  "MASAMUNE/SET_UNMOUNT_ON_COMPLETION";
export const MASAMUNE_SET_INTENT_URL = "MASAMUNE/SET_INTENT_URL";
export const MASAMUNE_SET_YODLEE_CONNECTION = "MASAMUNE/SET_YODLEE_CONNECTION";
export const MASAMUNE_SET_AKOYA_CONNECTION = "MASAMUNE/SET_AKOYA_CONNECTION";
export const MASAMUNE_SET_SOPHTRON_CONNECTION =
  "MASAMUNE/SET_SOPHTRON_CONNECTION";
export const MASAMUNE_SET_UCW_CONNECTION = "MASAMUNE/SET_UCW_CONNECTION";
export const MASAMUNE_SET_FLINKS_CONNECTION = "MASAMUNE/SET_FLINKS_CONNECTION";
export const MASAMUNE_FORCE_SHOW_BANK_SELECTION_SCREEN_FOR_PROVIDER =
  "MASAMUNE/FORCE_SHOW_BANK_SELECTION_SCREEN_FOR_PROVIDER";
export const MASAMUNE_SET_CUSTOM_SELECTED_INSTITUTION_FOR_PROVIDER =
  "MASAMUNE/SET_CUSTOM_SELECTED_INSTITUTION_FOR_PROVIDER";
export const MASAMUNE_SET_SWITCH_TO_NEXT_VENDOR =
  "MASAMUNE/SET_SWITCH_TO_NEXT_VENDOR";
export const MASAMUNE_SET_CRITICAL_ISSUE_OCCURRED =
  "MASAMUNE/SET_CRITICAL_ISSUE_OCCURRED";
export const MASAMUNE_REUSE_EXISTING_CONNECTION =
  "MASAMUNE/REUSE_EXISTING_CONNECTION";
export const MASAMUNE_RUN_PRODUCT_REQUEST_POLLING =
  "MASAMUNE/RUN_PRODUCT_REQUEST_POLLING";
export const MASAMUNE_STOP_PRODUCT_REQUEST_POLLING =
  "MASAMUNE/STOP_PRODUCT_REQUEST_POLLING";
export const MASAMUNE_SET_CURRENT_CONSUMER = "MASAMUNE/SET_CURRENT_CONSUMER";
export const MASAMUNE_INIT_WIDGET = "MASAMUNE/INIT_WIDGET";
export const MASAMUNE_LOG_WIDGET = "MASAMUNE/LOG_WIDGET";
export const MASAMUNE_SET_ACCESS_TOKEN = "MASAMUNE/SET_ACCESS_TOKEN";
export const MASAMUNE_SET_CURRENT_VENDOR = "MASAMUNE/SET_VENDOR";
export const MASAMUNE_CLIENT_CALLBACK_SETUP = "MASAMUNE/CLIENT_CALLBACK_SETUP";
export const MASAMUNE_SET_IS_LAST_VENDOR = "MASAMUNE/SET_IS_LAST_VENDOR";
export const MASAMUNE_SET_CURRENT_WIDGET_FLOW =
  "MASAMUNE/SET_CURRENT_WIDGET_FLOW";
export const MASAMUNE_SET_FOUND_INSTITUTIONS =
  "MASAMUNE/SET_FOUND_INSTITUTIONS";
export const MASAMUNE_FLOW_CHANGE = "MASAMUNE/FLOW_CHANGE";
export const MASAMUNE_CLOSE_MODALS_AND_WIDGETS =
  "MASAMUNE/CLOSE_MODALS_AND_WIDGETS";
export const MASAMUNE_CONNECTION_POLLING = "MASAMUNE/CONNECTION_POLLING";
export const MASAMUNE_STOP_IBV_CONNECTION_POLLING =
  "MASAMUNE/STOP_IBV_CONNECTION_POLLING";
export const MASAMUNE_SET_CONNECTION_PROCESSING_TIME =
  "MASAMUNE/SET_CONNECTION_PROCESSING_TIME";
export const MASAMUNE_CLEAR_CONNECTION_PROCESSING_TIME =
  "MASAMUNE/CLEAR_CONNECTION_PROCESSING_TIME";

export const MASAMUNE_INDEX_CHANGED = "MASAMUNE/INDEX_CHANGED";
export const MASAMUNE_SET_ERRORED_INSTITUTIONS =
  "MASAMUNE/SET_ERRORED_INSTITUTIONS";
export const MASAMUNE_SET_RETRY_MODE = "MASAMUNE/SET_RETRY_MODE";
export const MASAMUNE_SET_IS_LANDING_PAGE = "MASAMUNE/SET_IS_LANDING_PAGE";

/// MASAMUNE WIDGET FLOWS
export const NEW_CONNECTION = "new_connection";
export const CONNECTION_FREEZE = "connection_freeze";
export const REUSE_CONNECTION = "reuse_connection";
export const SWITCH_VENDOR = "switch_vendor";
export const CRITICAL_ERROR = "critical_error";
export const REQUEST_PROCESSING = "request_processing";
export const MULTIPLE_INSTITUTIONS_SCREEN = "select_institution";
export const NEW_CONNECTION_WITH_INSTITUTION_SELECTION =
  "new_connection_with_selection_of_institution";
export const WIDGET_LOADING = "widget_loading";
export const BANK_SELECTION = "bank_selection";
export const REQUEST_COMPLETE = "request_complete";
export const MASAMUNE_PRODUCT_REQUEST_FAILED_TO_PROCESS =
  "product_request_failed_to_process";
export const MASAMUNE_SHOW_MODAL = "MASAMUNE/SHOW_MODAL";
export const MASAMUNE_HIDE_MODAL = "MASAMUNE/HIDE_MODAL";
export const MASAMUNE_CONNECTION_FREEZE = "MASAMUNE/CONNECTION_FREEZE";

/// Messages

export const AKOYA_IN_PROGRESS_MSG =
  "Please proceed to the new window opened to connect your account. If you have closed the window or selected the wrong institution, please refresh this page to restart.";
export const NO_VENDORS_IN_SEQUENCE = "no_vendors_in_sequence";

export const MASAMUNE_ADD_SEQUENCE_HISTORY = "MASAMUNE/ADD_SEQUENCE_HISTORY";
