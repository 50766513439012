import {
  call,
  delay,
  getContext,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  runProductRequestPolling,
  setAkoyaConnection,
  setCallbackFunctions,
  setCriticalIssueOccurred,
  setCurrentConsumer,
  setCurrentProvider,
  setCurrentWidgetFlow,
  setDataPullProcessingTime,
  setDataPullTimeout,
  setFlinksConnection,
  setFoundInstitutions,
  setErroredInstitutions,
  setIbvSequence,
  setIntentUrl,
  setIsLastVendor,
  setSophtronConnection,
  setSwitchToNextVendor,
  setUnmountOnCompletion,
  setWidgetConfiguration,
  setWidgetFlow,
  setYodleeConnection,
  showModal,
  stopConnectionPolling,
  stopProductRequestPolling,
  toggleLoading,
  toggleProductRequestProcessing,
  toggleWidgetProcessing,
  toggleWidgetRender,
  setIsLandingPage,
  addSequenceToHistory,
  setProductRequest,
  setTermsAndConditions,
  clearConnectionProcessingTime,
} from "../actions/widget-actions";
import {
  connectionProcessingTimedOut,
  createConsumer,
  createOrUpdateWebhooksDelay,
  findOrCreateProductRequest,
  getConnectionById,
  getConsumerInfo,
  getIbvSequence,
  getProductRequest,
  getTermsAndConditions,
  getWidgetConfiguration,
  ibvConnectionEstablished,
  ibvConnectionFailed,
  isLastVendorInTheSequence,
  isProductRequestFrozen,
  logWidgetParamsLog,
  normalizedIbvSequence,
  processingTimedOut,
  pullIbvData,
  shouldReuseExistingConnection,
} from "../shared/masamune-widget-utils";

import { setAkoyaLoadingAction } from "../actions/akoya-actions";
import { toggleBankSearchScreen } from "../actions/bank-selection-actions";
import { ACTIVE_CONNECTION_STATUSES } from "../constants/global";
import {
  BANK_SELECTION,
  CONNECTION_FREEZE,
  CRITICAL_ERROR,
  MASAMUNE_CLIENT_CALLBACK_SETUP,
  MASAMUNE_CLOSE_MODALS_AND_WIDGETS,
  MASAMUNE_CONNECTION_POLLING,
  MASAMUNE_INDEX_CHANGED,
  MASAMUNE_INIT_WIDGET,
  MASAMUNE_LOG_WIDGET,
  MASAMUNE_REUSE_EXISTING_CONNECTION,
  MASAMUNE_RUN_PRODUCT_REQUEST_POLLING,
  MASAMUNE_SET_CURRENT_VENDOR,
  MASAMUNE_SET_CURRENT_WIDGET_FLOW,
  MASAMUNE_SET_CUSTOM_SELECTED_INSTITUTION_FOR_PROVIDER,
  MASAMUNE_SET_IBV_SEQUENCE,
  MASAMUNE_SET_PRODUCT_REQUEST,
  MASAMUNE_SET_SWITCH_TO_NEXT_VENDOR,
  MASAMUNE_SET_WIDGET_CONFIGURATION,
  MASAMUNE_STOP_IBV_CONNECTION_POLLING,
  MASAMUNE_STOP_PRODUCT_REQUEST_POLLING,
  MASAMUNE_TOGGLE_LOADING,
  MASAMUNE_TOGGLE_PRODUCT_REQUEST_PROCESSING,
  MASAMUNE_TOGGLE_WIDGET_PROCESSING,
  MASAMUNE_TOGGLE_WIDGET_RENDER,
  MULTIPLE_INSTITUTIONS_SCREEN,
  NEW_CONNECTION,
  NEW_CONNECTION_WITH_INSTITUTION_SELECTION,
  NO_VENDORS_IN_SEQUENCE,
  REQUEST_COMPLETE,
  REQUEST_PROCESSING,
  REUSE_CONNECTION,
  SWITCH_VENDOR,
  WIDGET_LOADING,
} from "../constants/masamune-widget";
import { ApiErrorMessagesMapper, loginFailureCallbackPayload } from "../services/api-client";
import {
  productRequestComplete,
  productRequestFailedToProcess,
} from "../shared/masamune-widget-utils";
import { logBusinessDisplayed } from "../actions/business-actions";
import vendorErrorTypes from "../config/vendor-error-types.json";

const CHANGE_FLOW_EVENTS = [
  MASAMUNE_CLOSE_MODALS_AND_WIDGETS,
  MASAMUNE_SET_CUSTOM_SELECTED_INSTITUTION_FOR_PROVIDER,
  MASAMUNE_SET_WIDGET_CONFIGURATION,
  MASAMUNE_SET_PRODUCT_REQUEST,
  MASAMUNE_SET_CURRENT_VENDOR,
  MASAMUNE_TOGGLE_WIDGET_PROCESSING,
  MASAMUNE_TOGGLE_LOADING,
  MASAMUNE_SET_IBV_SEQUENCE,
  MASAMUNE_INDEX_CHANGED,
  MASAMUNE_SET_SWITCH_TO_NEXT_VENDOR,
  MASAMUNE_TOGGLE_PRODUCT_REQUEST_PROCESSING,
];

function* initWidgetWorker(action) {
  const payload = action.payload;
  const widgetProps = payload.props;
  const onError = widgetProps.onError;
  const accessToken = widgetProps.access_token;

  const rollbar = yield getContext("rollbar");
  // Get current customer and set active connections if any
  let consumer;

  try {
    yield put(toggleWidgetProcessing(true));
    consumer = yield call(
      getConsumerInfo,
      widgetProps.consumer_data.consumer_id,
      widgetProps.access_token
    );
    yield put(toggleWidgetProcessing(false));
  } catch (e) {
    if (
      e?.cause?.statusCode === 401 ||
      e?.cause?.statusCode === 500 ||
      e?.cause?.statusCode === 400 ||
      e?.cause?.statusCode === 422
    ) {
      rollbar.error("Error during the Masamune widget initialization", e, {
        params: widgetProps,
      });

      yield call(onError, ApiErrorMessagesMapper(e));
      yield put(toggleWidgetProcessing(false));
      return yield put(toggleWidgetRender());
    }
    if (e?.cause?.statusCode === 404) {
      try {
        consumer = yield call(
          createConsumer,
          widgetProps.consumer_data,
          widgetProps.access_token
        );
      } catch (e) {
        rollbar.error("Error during finding or creating consumer", e, {
          params: widgetProps,
        });

        yield call(onError, ApiErrorMessagesMapper(e));
        yield put(toggleWidgetProcessing(false));
        return yield put(toggleWidgetRender());
      }
    }
  }

  yield put(
    logBusinessDisplayed({ consumer_id: widgetProps.consumer_data.consumer_id })
  );
  yield put(setCurrentConsumer(consumer));

  const termsAndConditions = yield call(getTermsAndConditions, { accessToken: widgetProps.access_token });
  yield put(
    setTermsAndConditions({ termsAndConditions: termsAndConditions })
  );

  const widgetConfig = yield call(getWidgetConfiguration, {
    vendor: "masamune",
    accessToken: widgetProps.access_token,
  });

  yield put(
    setWidgetConfiguration({
      widgetConfiguration: widgetConfig["widget_configuration"],
    })
  );

  try {
    const productRequest = yield call(
      findOrCreateProductRequest,
      widgetProps.consumer_data,
      widgetProps.product_request_id,
      widgetProps.access_token
    );

    if (
      widgetProps.product_request_id &&
      (productRequest.consumer_id !== widgetProps.consumer_data.consumer_id)
    ) {
      const message = "The consumer id does not match the product request";
      rollbar.error(message);
      yield call(onError, { message });
      yield put(toggleWidgetProcessing(false));
      return yield put(toggleWidgetRender());
    }

    yield put(setProductRequest(productRequest));
    const routingNumber = widgetProps.consumer_data.routing_number || productRequest.routing_number;
    const ibvSequenceResponse = yield call(getIbvSequence, {
      routingNumber: routingNumber,
      consumer,
      accessToken,
    });

    yield call(
      createOrUpdateWebhooksDelay,
      widgetProps?.webhooks_delay || null,
      widgetProps.access_token
    );

    if (ibvSequenceResponse["vendors"].length == 0) {
      yield put(
        setErroredInstitutions(ibvSequenceResponse["errored_institutions"])
      );
      return yield put(setCurrentWidgetFlow(NO_VENDORS_IN_SEQUENCE));
    }

    const normalizedIbvSequenceResponse =
      normalizedIbvSequence(ibvSequenceResponse);

    yield put(addSequenceToHistory(normalizedIbvSequenceResponse));
    yield put(setIbvSequence(normalizedIbvSequenceResponse));
    yield put(setDataPullTimeout(widgetProps.data_pull_timeout));
    yield put(setIntentUrl(widgetProps.intent_url));
    yield put(setIsLandingPage(widgetProps.edge_landing_page));

    if (widgetProps.unmountOnCompletion !== undefined)
      yield put(setUnmountOnCompletion(widgetProps.unmountOnCompletion));

    yield put(
      setWidgetConfiguration({
        clientId: consumer.client_id,
        edgeClientId: consumer.edge_client_id,
      })
    );

    yield put(toggleLoading());
    yield put(toggleWidgetProcessing(false));
    yield put(logBusinessDisplayed({
      consumer_id: widgetProps.consumer_data.consumer_id,
      institution_id: ibvSequenceResponse.vendors[0].institutions[0]?.institution_id
    }));
    // Toggle widget loading
  } catch (e) {
    rollbar.error("Error during the widget initialization", e);
    if (e?.cause?.statusCode >= 400) {
      yield call(onError, ApiErrorMessagesMapper(e));
      yield put(toggleWidgetProcessing(false));

      return yield put(toggleWidgetRender());
    }
  }
}

function* logWidgetEventWorker(action) {
  const { eventPayload, vendor } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  if (eventPayload?.fnToCall === "renewClientSession") {
    return;
  }

  try {
    const params = {
      client_id: globalConfig.edgeClientId,
      product_request_id: globalConfig.productRequest?.product_request_id,
      payload: eventPayload,
      consumer_id: globalConfig.consumer?.client_consumer_id,
      vendor: vendor,
    };

    yield call(logWidgetParamsLog, params, accessToken);
  } catch (err) {
    return;
  }
}

function* clientCallbackSetupWorker(action) {
  const defaultCallback = () => {};
  const onSuccess = action.payload.onSuccess || defaultCallback;
  const onError = action.payload.onError || defaultCallback;
  const setCallbackPayload = { onSuccess, onError };
  yield put(setCallbackFunctions(setCallbackPayload));
}

function* watchInitWidget() {
  yield takeEvery(MASAMUNE_INIT_WIDGET, initWidgetWorker);
}

function* watchLogWidgetEvent() {
  yield takeEvery(MASAMUNE_LOG_WIDGET, logWidgetEventWorker);
}

function* watchClientCallbackSetup() {
  yield takeEvery(MASAMUNE_CLIENT_CALLBACK_SETUP, clientCallbackSetupWorker);
}

function* pollProductRequestWorker(action) {
  while (true) {
    yield delay(30000);

    const { productRequestId } = action.payload;
    // Access to the state scopes
    const globalConfig = yield select((state) => state.globalConfig);
    const rollbar = yield getContext("rollbar");
    const accessToken = globalConfig.accessToken;
    // Pick callbacks which where globally set
    const { onError, onSuccess } = globalConfig.clientCallbackFunctions;
    const pullProcessingStartTime = globalConfig.pullProcessingTimeStart;

    // The timeout after which we will stop the polling and un-mount the widget, could be overridden on the widget init
    const timeoutThreshold =
      globalConfig.dataPullTimeout ||
      window.NinjaFetchWidget.processingTimeoutThresholdInSec;
    // Get the corresponding product request
    try {
      const productRequest = yield call(
        getProductRequest,
        productRequestId,
        accessToken
      );

      // yield put(setProductRequest(productRequest));

      if (
        yield call(
          processingTimedOut,
          pullProcessingStartTime,
          timeoutThreshold
        )
      ) {
        yield put(toggleWidgetRender());
        yield put(toggleProductRequestProcessing(false));
        yield call(onError, {
          message: "Pull process timed out",
          isLastVendor: globalConfig.isLastVendor,
        });

        return yield put(stopProductRequestPolling());
      }

      if (yield call(productRequestComplete, productRequest)) {
        if (globalConfig.unmountOnCompletion) yield put(toggleWidgetRender());
        else yield put(setCurrentWidgetFlow(REQUEST_COMPLETE));

        yield put(toggleProductRequestProcessing(false));
        yield call(onSuccess, productRequest);

        return yield put(stopProductRequestPolling());
      }

      if (yield call(productRequestFailedToProcess, productRequest)) {
        yield put(toggleProductRequestProcessing(false));
        yield call(onError, {
          message: "Processing of your product request has failed",
          isLastVendor: globalConfig.isLastVendor,
        });

        yield put(toggleWidgetProcessing(false));
        yield put(setAkoyaLoadingAction(false));
        // yield put(setCurrentWidgetFlow(SWITCH_VENDOR));
        yield put(setSwitchToNextVendor());
        return yield put(stopProductRequestPolling());
      }
    } catch (e) {
      rollbar.error("Failed to process product request", e, {
        productRequestId: productRequestId,
      });
      yield put(setAkoyaLoadingAction(false));

      yield call(onError, {
        message: "Processing of your product request has failed",
        isLastVendor: globalConfig.isLastVendor,
      });
      yield put(toggleWidgetRender());
      return yield put(stopProductRequestPolling());
    }
  }
}

function* pollIbvConnectionWorker(action) {
  while (true) {
    yield delay(5000);

    const {
      accessToken,
      clientCallbackFunctions: { onError, onLoginFailure },
      connectionEstablishTimeout, // The timeout after which we will stop the polling and un-mount the widget, could be overridden on the widget init
      connectionProcessingTimeStart,
      isLastVendor,
    } = yield select(state => state.globalConfig);

    if (!connectionProcessingTimeStart) break;

    const { connectionId } = action.payload;
    const rollbar = yield getContext("rollbar");

    try {
      const ibvConnection = yield call(
        getConnectionById,
        connectionId,
        accessToken
      );

      switch (ibvConnection.vendor_name) {
        case "akoya":
          yield put(setAkoyaConnection(ibvConnection));
          break;
        case "yodlee":
          yield put(setYodleeConnection(ibvConnection));
          break;
        case "sophtron":
          yield put(setSophtronConnection(ibvConnection));
          break;
        case "flinks":
          yield put(setFlinksConnection(ibvConnection));
          break;
      }

      if (
        yield call(
          connectionProcessingTimedOut,
          connectionProcessingTimeStart,
          connectionEstablishTimeout
        )
      ) {
        yield put(setSwitchToNextVendor());
        yield put(setCriticalIssueOccurred());
        yield call(onError, {
          message: "Connection process timed out",
          isLastVendor,
        });

        return yield put(stopConnectionPolling());
      }

      if (yield call(ibvConnectionEstablished, ibvConnection)) {
        yield put(setAkoyaLoadingAction(false)); // TODO: expand to all vendors

        return yield put(stopConnectionPolling());
      }

      if (yield call(ibvConnectionFailed, ibvConnection)) {
        yield put(setAkoyaLoadingAction(false)); // TODO: expand to all vendors
        yield put(setSwitchToNextVendor());
        yield put(setCriticalIssueOccurred());

        // Login failure callback if akoya
        if (ibvConnection.vendor_name === 'akoya') {
          const errorType = vendorErrorTypes["akoya"][ibvConnection.vendor_response['message']];
          if (!errorType) rollbar.warning(`Undefined akoya error type: ${ibvConnection.vendor_response['message']}`);
          yield call(onLoginFailure, loginFailureCallbackPayload(errorType, ibvConnection.vendor_response));
        }

        yield call(onError, {
          message: "Error of connection processing",
          isLastVendor,
        });

        return yield put(stopConnectionPolling());
      }
    } catch (e) {
      rollbar.error("Failed to process connection", e, {
        connectionId: connectionId,
      });

      yield call(onError, {
        message: "Failed to process connection",
        isLastVendor,
      });
      yield put(setAkoyaLoadingAction(false));
      yield put(setSwitchToNextVendor());
      yield put(setCriticalIssueOccurred());

      return yield put(stopConnectionPolling());
    }
  }
}

function* watchProductRequestPollStart() {
  yield race({
    poll: yield takeEvery(
      MASAMUNE_RUN_PRODUCT_REQUEST_POLLING,
      pollProductRequestWorker
    ),
    cancel: yield take(MASAMUNE_STOP_PRODUCT_REQUEST_POLLING),
  });
}

function* watchIbvConnectionPollStart() {
  yield race({
    poll: yield takeEvery(MASAMUNE_CONNECTION_POLLING, pollIbvConnectionWorker),
    cancel: yield take(MASAMUNE_STOP_IBV_CONNECTION_POLLING),
  });
}

function* handleConnectionReuseWorker(action) {
  const { productRequestId, connection, vendor } = action.payload;
  const {
    accessToken,
    clientCallbackFunctions: { onSuccess },
    productRequest,
  } = yield select((state) => state.globalConfig);

  if (
    connection &&
    ACTIVE_CONNECTION_STATUSES.indexOf(connection.status) !== -1
  ) {
    yield put(setDataPullProcessingTime());
    yield put(toggleProductRequestProcessing(true));

    yield call(
      pullIbvData,
      connection.id,
      productRequestId,
      vendor,
      accessToken
    );

    yield call(onSuccess, productRequest);

    yield put(setWidgetFlow());
    yield put(runProductRequestPolling({ productRequestId, accessToken }));
  }
}

function* watchConnectionReuse() {
  yield takeEvery(
    MASAMUNE_REUSE_EXISTING_CONNECTION,
    handleConnectionReuseWorker
  );
}

function* handleFlowChangeWorker() {
  const globalConfig = yield select((state) => state.globalConfig);
  const currentProvider = globalConfig.currentProvider;
  const vendor = currentProvider?.vendor_name;
  const foundInstitutions = currentProvider ? currentProvider.institutions : [];
  const existingConnection = globalConfig.ibvConnections[vendor];
  const existingProductRequestId =
    globalConfig.productRequest?.product_request_id;

  if (globalConfig.connectionProcessingTimeStart) {
    yield put(clearConnectionProcessingTime());
  }

  if (!globalConfig.shouldRender || globalConfig.flow === REQUEST_COMPLETE) {
    return;
  }

  if (!currentProvider || globalConfig.isLoading) {
    return yield put(setCurrentWidgetFlow(WIDGET_LOADING));
  }

  if (globalConfig.retryWithBankSelection) {
    return yield put(setCurrentWidgetFlow(BANK_SELECTION)); // Clicked retry after bank failure
  }

  // Show next vendor screen
  if (globalConfig.switchToNextVendor) {
    return yield put(setCurrentWidgetFlow(SWITCH_VENDOR));
  }

  const requestFreeze = yield call(isProductRequestFrozen, globalConfig.productRequest?.product_request_id, globalConfig);

  if (requestFreeze && !globalConfig.productRequestProcessing) {
    if (globalConfig.flow !== CONNECTION_FREEZE) {
      const { onError } = globalConfig.clientCallbackFunctions;
      yield call(
        onError,
        { message: "Another data pull is already in progress" }
      )
    }
    return yield put(setCurrentWidgetFlow(CONNECTION_FREEZE)); // Toggle processing if there is something processing during N minutes timeframe
  }

  const shouldReuse = yield shouldReuseExistingConnection(
    existingConnection,
    existingProductRequestId,
    globalConfig.accessToken
  );

  if (
    shouldReuse &&
    !globalConfig.productRequestProcessing &&
    !globalConfig.processing
  ) {
    return yield put(setCurrentWidgetFlow(REUSE_CONNECTION));
  }

  // Processing message
  if (globalConfig.productRequestProcessing) {
    return yield put(setCurrentWidgetFlow(REQUEST_PROCESSING));
  }

  // Critical issue appeared
  if (
    globalConfig.switchToNextVendor &&
    globalConfig.isLastVendor &&
    globalConfig.criticalIssueOccurred &&
    !globalConfig.isLoading &&
    !globalConfig.productRequestProcessing
  ) {
    return yield put(setCurrentWidgetFlow(CRITICAL_ERROR));
  }

  // Show institutions selection screen
  if (
    foundInstitutions.length > 1 &&
    vendor &&
    !globalConfig.productRequestProcessing &&
    !globalConfig.customInstitutionSelected[vendor] &&
    !globalConfig.forceShowBankSelectionScreenForProvider[vendor]
  ) {
    return yield put(setCurrentWidgetFlow(MULTIPLE_INSTITUTIONS_SCREEN));
  }

  if (
    currentProvider?.institutions?.length === 0 &&
    !globalConfig.customInstitutionSelected[vendor] &&
    !globalConfig.forceShowBankSelectionScreenForProvider[vendor] &&
    !globalConfig.productRequestProcessing &&
    globalConfig?.widgetConfiguration?.features?.bankSelection // check if feature turned on
  ) {
    yield put(setCurrentWidgetFlow(BANK_SELECTION)); // default flow
    return yield put(toggleBankSearchScreen(true));
  }

  if (
    currentProvider &&
    !globalConfig.isLoading &&
    // !globalConfig.processing &&
    !globalConfig.productRequestProcessing &&
    (shouldReuse == undefined || shouldReuse == false)
  ) {
    if (globalConfig.flow === NEW_CONNECTION_WITH_INSTITUTION_SELECTION) return;
    return yield put(setCurrentWidgetFlow(NEW_CONNECTION)); // default flow
  }

  yield put(setCurrentWidgetFlow(WIDGET_LOADING));
}

function* handleSetOfCurrentProvider() {
  const globalConfig = yield select((state) => state.globalConfig);

  const vendorSequence = globalConfig.vendorSequence;
  const currVendorIdx = globalConfig.currVendorIdx;
  const ibvSequence = globalConfig.ibvSequence;
  const vendor = ibvSequence?.vendors[currVendorIdx];
  const institutions = vendor ? vendor.institutions : [];
  const isLastVendor = yield call(
    isLastVendorInTheSequence,
    vendorSequence,
    currVendorIdx
  );

  if (isLastVendor) {
    yield put(setIsLastVendor());
  }

  yield put(setCurrentProvider(vendor));
  yield put(setFoundInstitutions(institutions));
}

function* watchCurrentProviderChanges() {
  yield takeLatest(
    [MASAMUNE_SET_IBV_SEQUENCE, MASAMUNE_INDEX_CHANGED],
    handleSetOfCurrentProvider
  );
}

function* watchFlowChange() {
  yield race({
    flow: yield takeLatest(CHANGE_FLOW_EVENTS, handleFlowChangeWorker),
    cancel: yield take(MASAMUNE_TOGGLE_WIDGET_RENDER),
  });
}

function* handleModalToggleWorker(action) {
  const automaticShowWidgetOnFlows = [
    CRITICAL_ERROR,
    SWITCH_VENDOR,
    NEW_CONNECTION,
  ];
  const flow = action.payload.flow;

  if (automaticShowWidgetOnFlows.indexOf(flow) > -1) {
    yield put(showModal());
  }
}

function* watchModalToggle() {
  yield takeLatest(MASAMUNE_SET_CURRENT_WIDGET_FLOW, handleModalToggleWorker);
}

export default function* masamuneWidgetSagas() {
  yield spawn(watchModalToggle);
  yield spawn(watchConnectionReuse);
  yield spawn(watchLogWidgetEvent);
  yield spawn(watchInitWidget);
  yield spawn(watchFlowChange);
  yield spawn(watchCurrentProviderChanges);
  yield spawn(watchProductRequestPollStart);
  yield spawn(watchIbvConnectionPollStart);
  yield spawn(watchClientCallbackSetup);
}
