export const AKOYA_INIT = "AKOYA/INIT";
export const AKOYA_TOGGLE_LOADING = "AKOYA/TOGGLE_LOADING";
export const AKOYA_CONNECTION_UPDATE = "AKOYA/CONNECTION_UPDATE";
export const AKOYA_SET_WIDGET_CONFIGURATION = "AKOYA/SET_WIDGET_CONFIGURATION";
export const AKOYA_SET_ERROR = "AKOYA/SET_ERROR";

export const AKOYA_PULL_IN_PROGRESS_STATUSES = [
  "login_success",
  "data_pull_in_progress",
  "data_pull_success"
];
