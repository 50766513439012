import { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  initializeAkoyaWidgetAction,
  setAkoyaLoadingAction,
  updateAkoyaConnectionAction,
} from "../../actions/akoya-actions";
import { logBankSelectionEvent } from "../../actions/bank-selection-actions";
import {
  runProductRequestPolling,
  setConnectionProcessingTime,
  setDataPullProcessingTime,
  startConnectionPolling,
  setSwitchToNextVendor,
  toggleProductRequestProcessing,
  toggleWidgetRender,
  setAkoyaConnection,
} from "../../actions/widget-actions";
import { ApiErrorMessagesMapper } from "../../services/api-client";
import { getConnectionById, sendMessageToNative } from "../../shared/masamune-widget-utils";
import LoadingButton from "../shared/loading-button";
import { logBusinessClicked } from "../../actions/business-actions";
import { MonitoringNotificationsService } from "../../services/monitoring-notification-service";
import ConnectMyAccountButton from "../masamune-widget/connect-my-account-button";
import { AKOYA_PULL_IN_PROGRESS_STATUSES } from "../../constants/akoya-widget";

const rollbar = MonitoringNotificationsService("rollbar");

export const AkoyaWidget = ({
  institution,
  onError,
  onSuccess,
  productRequestId
}) => {
  const dispatch = useDispatch();

  const akoyaLink = useSelector((state) => state.akoyaConfig.akoyaLink);
  const {
    akoyaConnection,
    consumer,
    isLastVendor,
    productRequest,
    shouldRender,
    widgetConfiguration,
    sequenceHistory,
    accessToken
  } = useSelector(state => state.globalConfig);

  const [verifyingConnection, setVerifyingConnection] = useState(false);
  const akoyaOAuthWindow = useRef(undefined);

  const initWidget = () => {
    const widgetInitParams = { productRequestId, institution };
    dispatch(setAkoyaLoadingAction(true));
    dispatch(initializeAkoyaWidgetAction(widgetInitParams));
  };

  const getConnection = async () => {
    if (!akoyaConnection?.id) return;

    return await getConnectionById(akoyaConnection.id, accessToken);
  }

  useEffect(() => {
    const checkInterval = setInterval(async () => {
      // Do not initiate retry if about to start product request polling
      if (AKOYA_PULL_IN_PROGRESS_STATUSES.includes(akoyaConnection?.status)) {
        clearInterval(checkInterval);
        return;
      }

      const oauthWindow = akoyaOAuthWindow.current;

      if (oauthWindow && oauthWindow.closed) {
        clearInterval(checkInterval);
        setVerifyingConnection(true);
        // Wait 5 seconds to handle situations where users close oath window before being instructed to
        setTimeout(async () => {
          const connection = await getConnection();

          if (AKOYA_PULL_IN_PROGRESS_STATUSES.includes(connection?.status)) {
            dispatch(setAkoyaConnection(connection));
          }
          else {
            dispatch(setSwitchToNextVendor());
          }

          setVerifyingConnection(false);
        }, 5000);
      }
    }, 1000);

    return () => clearInterval(checkInterval);
  }, [akoyaConnection?.id]);

  useEffect(() => {
    try {
      if (shouldRender) {
        initWidget();
      }
    } catch (e) {
      rollbar.error("Failed to init Akoya widget", e);
      onError(ApiErrorMessagesMapper(e, { isLastVendor: isLastVendor }));

      if (e.cause.statusCode === 401) {
        dispatch(toggleWidgetRender());
      }
    }
  }, []);

  useEffect(() => {
    if (
      widgetConfiguration?.features?.ucwBankSelectionMode &&
      sequenceHistory.length > 1 &&
      akoyaLink
    ) {
      loadAkoyaWidget();
    }
  }, [akoyaLink]);

  useEffect(() => {
    if (akoyaConnection?.id) {
      dispatch(setConnectionProcessingTime());
      dispatch(
        startConnectionPolling({
          connectionId: akoyaConnection?.id,
        })
      );
    }
  }, [akoyaConnection?.id]);

  useEffect(() => {
    /// Start data pull processing
    if (AKOYA_PULL_IN_PROGRESS_STATUSES.includes(akoyaConnection?.status)) {
      onSuccess(productRequest);
      dispatch(toggleProductRequestProcessing(true));
      dispatch(setDataPullProcessingTime());
      dispatch(runProductRequestPolling({ productRequestId }));
    }
  }, [productRequestId, akoyaConnection?.status]);

  const openAkoyaWindow = () => {
    akoyaOAuthWindow.current = window.open(
      akoyaLink,
      "Institution connection",
      "menubar=no,status=no"
    );

    return (window.akoyaOAuthWindow = akoyaOAuthWindow.current);
  };

  const loadAkoyaWidget = () => {
    dispatch(
      updateAkoyaConnectionAction({
        connectionId: akoyaConnection?.id,
        productRequestId,
        status: "login_in_progress",
      })
    );

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "AKOYA_WIDGET_LOADED",
        },
      })
    );

    sendMessageToNative({
      event: "open_url",
      url: akoyaLink,
    });

    return openAkoyaWindow();
  };

  const handleActiveBtnClick = (e, consumer, productRequest) => {
    e.preventDefault();

    dispatch(
      logBusinessClicked({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.id,
        vendor_name: "akoya",
        institution_id: institution?.institution_id,
      })
    );

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "AKOYA_CONNECT_BTN_CLICKED",
        },
      })
    );

    loadAkoyaWidget();
  };

  const verifyingConnectionBtn = () => {
    return <LoadingButton text='Verifying Connection...' disabled={true} />;
  };

  const activeBtn = () => {
    return (
      <ConnectMyAccountButton
        handleActiveBtnClick={(e) => {
          handleActiveBtnClick(e, consumer, productRequest);
        }}
      />
    );
  };

  if (!widgetConfiguration?.features?.bankSelection) {
    return (
      <div className="ninja-fetch-widget__body flex justify-center">
        {verifyingConnection ? verifyingConnectionBtn() : akoyaLink ? activeBtn() : <LoadingButton />}
      </div>
    );
  }
};

export default connect(null)(AkoyaWidget);
