import ApiClient from "../services/api-client";
import {
  BUSINESS_WIDGET_BANK_SELECTION_OPENED,
  BUSINESS_WIDGET_BANK_SELECTION_SELECTED,
  BUSINESS_WIDGET_CONNECT_CLICKED,
  BUSINESS_WIDGET_INSTITUTION_PRESELECTED,
  BUSINESS_WIDGET_LANDING_DISPLAYED,
  BUSINESS_WIDGET_LOGIN_FAILED,
  BUSINESS_WIDGET_LOGIN_SUCCEEDED,
  BUSINESS_WIDGET_NEW_INSTITUTION_CLICKED,
  BUSINESS_WIDGET_RETRY_CLICKED,
  BUSINESS_WIDGET_RETRY_DISPLAYED,
  BUSINESS_WIDGET_REUSE_INSTITUTION_CLICKED,
  BUSINESS_WIDGET_SEARCH_CLICKED,
  BUSINESS_WIDGET_SWITCH_CLICKED,
} from "../constants/business";

export const loggerMiddleware = (store) => (next) => (action) => {
  let { globalConfig } = store.getState();
  let { accessToken } = globalConfig;
  switch (action.type) {
    case BUSINESS_WIDGET_NEW_INSTITUTION_CLICKED:
      ApiClient.logBusinessEvent(
        {
          ...action.payload,
          event_category: "LANDING",
          event_name: "NEW_INSTITUTION_CLICKED",
        },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_REUSE_INSTITUTION_CLICKED:
      ApiClient.logBusinessEvent(
        {
          ...action.payload,
          event_category: "LANDING",
          event_name: "REUSE_INSTITUTION_CLICKED",
        },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_INSTITUTION_PRESELECTED:
      ApiClient.logBusinessEvent(
        {
          ...action.payload,
          event_category: "LANDING",
          event_name: "INSTITUTION_PRESELECTED",
        },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_LANDING_DISPLAYED:
      ApiClient.logBusinessEvent(
        {
          ...action.payload,
          event_category: "LANDING",
          event_name: "DISPLAYED",
        },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_CONNECT_CLICKED:
      ApiClient.logBusinessEvent(
        {
          ...action.payload,
          event_category: "LANDING",
          event_name: "CONNECT_CLICKED",
        },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_BANK_SELECTION_OPENED:
      ApiClient.logBusinessEvent(
        {
          ...action.payload,
          event_category: "BANK_SELECTION",
          event_name: "OPENED",
        },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_BANK_SELECTION_SELECTED:
      ApiClient.logBusinessEvent(
        {
          ...action.payload,
          event_category: "BANK_SELECTION",
          event_name: "SELECTED",
        },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_LOGIN_FAILED:
      ApiClient.logBusinessEvent(
        { ...action.payload, event_category: "LOGIN", event_name: "FAILED" },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_LOGIN_SUCCEEDED:
      ApiClient.logBusinessEvent(
        { ...action.payload, event_category: "LOGIN", event_name: "SUCCESS" },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_RETRY_DISPLAYED:
      ApiClient.logBusinessEvent(
        {
          ...action.payload,
          event_category: "RETRY",
          event_name: "DISPLAYED",
        },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_RETRY_CLICKED:
      ApiClient.logBusinessEvent(
        { ...action.payload, event_category: "RETRY", event_name: "CLICKED" },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_SWITCH_CLICKED:
      ApiClient.logBusinessEvent(
        { ...action.payload, event_category: "SWITCH", event_name: "CLICKED" },
        accessToken
      );
      break;
    case BUSINESS_WIDGET_SEARCH_CLICKED:
      ApiClient.logBusinessEvent(
        {
          ...action.payload,
          event_category: "SEARCH",
          event_name: "CLICKED",
        },
        accessToken
      );
      break;
    default:
      break;
  }
  let result = next(action);
  return result;
};
