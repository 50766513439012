import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { setConsents } from "../../actions/widget-actions";
import { CONNECTION_FREEZE, REQUEST_PROCESSING } from "../../constants/masamune-widget";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const { termsAndConditions, consentsAccepted, flow } = useSelector((state) => state.globalConfig);
  const processingRequest = [REQUEST_PROCESSING, CONNECTION_FREEZE].includes(flow)

  // Initialize local state for consents even if termsAndConditions might be null
  const [consentState, setConsentState] = useState([]);

  // When termsAndConditions change, initialize the consentState with IDs from T&C
  useEffect(() => {
    if (Array.isArray(termsAndConditions)) {
      setConsentState(
        termsAndConditions.map(tc => ({
          slug: tc.slug,
          is_required: !!tc.is_required,
          checked: !!tc.checkbox_default_value,
        }))
      );
    }
  }, [termsAndConditions]);

  // Whenever consentState changes, update the global store with the current consents
  useEffect(() => {
    if (!Array.isArray(termsAndConditions)) return;

    // Dispatch the updated consents without any "allConsentsGiven" flag
    dispatch(setConsents({ consents: consentState }));
  }, [consentState, dispatch, termsAndConditions]);

  // Handle checkbox changes by updating local consentState
  const handleCheckboxChange = (slug, checked) => {
    setConsentState(prev =>
      prev.map(item =>
        item.slug === slug ? { ...item, checked } : item
      )
    );
  };

  // If termsAndConditions is null, render loading
  if (termsAndConditions === null) {
    return (
      <div className="text-center ninja-fetch-widget__terms-and-conditions">
        <svg
          role="img"
          width="400"
          height="50"
          aria-labelledby="loading-aria"
          viewBox="0 0 400 50"
          preserveAspectRatio="none"
        >
          <title id="loading-aria">Loading...</title>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            clipPath="url(#clip-path)"
            style={{ fill: 'url(#fill)' }}
          />
          <defs>
            <clipPath id="clip-path">
              <rect x="33" y="4" rx="3" ry="3" width="363" height="8" />
              <rect x="33" y="20" rx="3" ry="3" width="291" height="8" />
              <rect x="7" y="7" rx="0" ry="0" width="15" height="16" />
            </clipPath>
            <linearGradient id="fill">
              <stop
                offset="0.599964"
                stopColor="#f3f3f3"
                stopOpacity="1"
              >
                <animate
                  attributeName="offset"
                  values="-2; -2; 1"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop
                offset="1.59996"
                stopColor="#ecebeb"
                stopOpacity="1"
              >
                <animate
                  attributeName="offset"
                  values="-1; -1; 2"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop
                offset="2.59996"
                stopColor="#f3f3f3"
                stopOpacity="1"
              >
                <animate
                  attributeName="offset"
                  values="0; 0; 3"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    );
  }

  // If termsAndConditions is not an array, return null
  if (processingRequest || consentsAccepted || !Array.isArray(termsAndConditions)) { return null; }

  // Render the T&Cs
  return (
    <div className="text-center ninja-fetch-widget__terms-and-conditions">
      {termsAndConditions.map((tc) => {
        const consentItem = consentState.find(item => item.slug === tc.slug);
        const isChecked = consentItem ? consentItem.checked : tc.checkbox_default_value;

        return (
          <div key={tc.slug} className="mb-4">
            {tc.display_checkbox ? (
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox mr-2"
                  checked={!!isChecked}
                  onChange={(e) => handleCheckboxChange(tc.slug, e.target.checked)}
                  required={!!tc.is_required}
                />
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: ({ href, children }) => (
                      <a
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 underline hover:text-blue-700"
                      >
                        {children}
                      </a>
                    ),
                    p: ({ children }) => <p className="inline">{children}</p>,
                  }}
                >
                  {tc.md_text}
                </ReactMarkdown>
                {tc.is_required && <span className="text-red-600 ml-1">*</span>}
              </label>
            ) : (
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: ({ href, children }) => (
                    <a
                      href={href}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      {children}
                    </a>
                  ),
                  p: ({ children }) => <p className="inline">{children}</p>,
                }}
              >
                {tc.md_text}
              </ReactMarkdown>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TermsAndConditions;
