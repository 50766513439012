import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  logBankSelectionEvent,
  setFoundInstitutions,
  setQuery,
} from "../../actions/bank-selection-actions";
import {
  acceptConsents,
  changeMasamuneCurrentVendorIndex,
  setCurrentWidgetFlow,
  setIbvSequence,
  setWidgetConfiguration,
} from "../../actions/widget-actions";
import {
  logBusinessRetryClicked,
  logBusinessRetryDisplayed,
  logBusinessSearchClicked,
  logBusinessSwitchClicked,
} from "../../actions/business-actions";
import { NEW_CONNECTION_WITH_INSTITUTION_SELECTION } from "../../constants/masamune-widget";
import { requiredConsentsGiven, updateConsents } from "../../shared/masamune-widget-utils"; // Added import

const RetryDialog = (props) => {
  const dispatch = useDispatch();
  const globalConfig = useSelector((state) => state.globalConfig);
  const currIdx = useSelector((state) => state.globalConfig.currVendorIdx);
  const ibvSequence = useSelector((state) => state.globalConfig.ibvSequence);
  const consumer = useSelector((state) => state.globalConfig.consumer);
  const sequenceHistory = useSelector(
    (state) => state.globalConfig.sequenceHistory
  );
  const productRequest = useSelector(
    (state) => state.globalConfig.productRequest
  );
  const widgetConfiguration = useSelector(
    (state) => state.globalConfig.widgetConfiguration
  );
  const { retryFlowInstantBankSearch } = useSelector(
    (state) => state.globalConfig
  );
  const consents = useSelector((state) => state.globalConfig.consents); // Added
  const consentsGiven = requiredConsentsGiven(globalConfig); // Added
  const { routingNumber } = props;

  const currentVendor = ibvSequence.vendors[currIdx];

  const enableSearch = (
    routingNumber &&
    !widgetConfiguration.features.bankSelection &&
    currentVendor &&
    (currentVendor.institutions.length > 0) &&
    (
      widgetConfiguration.features.enableSearchOnWrongAba ||
      (
        !widgetConfiguration.features.disableAkoyaSearch &&
        currentVendor.vendor_name === "akoya"
      )
    )
  );

  const ibvEventParams = {
    consumer_id: consumer.client_consumer_id,
    product_request_id: productRequest?.product_request_id,
    vendor_name: currentVendor.vendor_name,
  };

  const handleRetryClick = (e) => {
    e.preventDefault();
    dispatch(logBusinessRetryClicked(ibvEventParams));

    updateConsents(consents, productRequest?.product_request_id, globalConfig).then(() => {
      dispatch(acceptConsents({ consentsAccepted: true }));
      currentVendor.vendor_name === "akoya" ? switchToVendor(false) : showBankSearch();
    });
  };

  const handleSwitchClick = (e) => {
    e.preventDefault();
    dispatch(logBusinessSwitchClicked(ibvEventParams));

    updateConsents(consents, productRequest?.product_request_id, globalConfig).then(() => {
      dispatch(acceptConsents({ consentsAccepted: true }));
      switchToVendor();
    });
  };

  const handleBankSearchClick = (e) => {
    e.preventDefault();
    dispatch(logBusinessSearchClicked(ibvEventParams));

    updateConsents(consents, productRequest?.product_request_id, globalConfig).then(() => {
      dispatch(acceptConsents({ consentsAccepted: true }));
      showBankSearch();
    });
  };

  const switchToVendor = (nextVendor = true) => {
    let idxToSet = currIdx;
    if (nextVendor) idxToSet += 1;
    let vendor = ibvSequence.vendors[idxToSet];

    /// If retry after the last vendor circle back to the first one
    if (!widgetConfiguration?.features?.bankSelection && vendor == null) {
      idxToSet = 0;
      vendor = ibvSequence.vendors[idxToSet];
    }

    // Track event when customer selected an institution
    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "RETRY_CLICKED",
          details: {
            currentVendorDetails: vendor,
          },
        },
      })
    );

    dispatch(setQuery(""));
    dispatch(setFoundInstitutions([]));

    if (
      vendor.institutions.length === 0 &&
      widgetConfiguration?.features?.bankSelection
    ) {
      dispatch(changeMasamuneCurrentVendorIndex(currIdx));

      return dispatch(
        setWidgetConfiguration({
          criticalIssueOccurred: false,
          switchToNextVendor: false,
          retryMode: false,
          retryWithBankSelection: true,
        })
      );
    }
    dispatch(changeMasamuneCurrentVendorIndex(idxToSet));

    dispatch(
      setWidgetConfiguration({
        criticalIssueOccurred: false,
        switchToNextVendor: false,
        retryMode: true,
        showModal: false,
      })
    );
  };

  const handleUcwBankSearch = () => {
    const firstSequence = sequenceHistory[0];

    dispatch(setIbvSequence(firstSequence));
    dispatch(
      setWidgetConfiguration({
        criticalIssueOccurred: false,
        switchToNextVendor: false,
        retryMode: true,
        currIdx: 0,
      })
    );
  };

  const showBankSearch = () => {
    if (widgetConfiguration.features.ucwBankSelectionMode) {
      handleUcwBankSearch();
      return;
    }

    if (currentVendor?.vendor_name === "akoya") switchToVendor();
    dispatch(setCurrentWidgetFlow(NEW_CONNECTION_WITH_INSTITUTION_SELECTION));
    dispatch(
      setWidgetConfiguration({
        criticalIssueOccurred: false,
        switchToNextVendor: false,
        retryMode: true,
        showModal: false,
      })
    );
  };

  useEffect(() => {
    if (retryFlowInstantBankSearch) {
      showBankSearch();

      dispatch(setWidgetConfiguration({ retryFlowInstantBankSearch: false }));
    } else {
      dispatch(
        logBusinessRetryDisplayed({
          consumer_id: consumer?.client_consumer_id,
          product_request_id: productRequest?.product_request_id,
        })
      );
    }
  }, []);

  return (
    <div className="flex justify-center flex-col">
      <p className="text-center">
        We were unable to connect your account. <><b>Retry</b> with the same bank data provider or <b>Switch Provider</b> to try again.</>
      </p>
      <div className="ninja-fetch-widget__body flex flex-col sm:flex-row justify-center mt-10">
        <button
          onClick={handleRetryClick}
          disabled={!consentsGiven}
          className="
            py-4 px-8 lg:py-2 min-w-[200px] lg:px-4 rounded-full text-white font-bold bg-blue-500 hover:bg-blue-600
            focus:ring-4 focus:ring-blue-300 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 items-baseline
            disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-gray-700
          "
        >
          Retry
        </button>
        <button
          onClick={handleSwitchClick}
          disabled={!consentsGiven}
          className="
            py-4 px-8 lg:py-2 min-w-[200px] lg:px-4 rounded-full text-white font-bold bg-blue-500 hover:bg-blue-600
            focus:ring-4 focus:ring-blue-300 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 items-baseline
            disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-gray-700
          "
        >
          Switch Provider
        </button>
      </div>
      {enableSearch && (
        <p className="text-center mt-5">
          <a
            href="#"
            onClick={handleBankSearchClick}
            className="text-blue-500 underline hover:text-blue-700"
          >
            That was not my bank. Click here to select a different bank
          </a>
        </p>
      )}
    </div>
  );
};

export default connect(null)(RetryDialog);
